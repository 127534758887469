<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeForm"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ selectedEvent ? 'EVENT' : 'CREATE EVENT' }}
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                :disabled="selectedEvent != undefined"
            >
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            prepend-icon="mdi-format-title"
                            label="Title *"
                            :rules="[rules.required]"
                            required
                            v-model="event.title"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="6" md="6">
                        <div class="d-flex">
                            <v-datetime-picker
                                v-model="event.date"
                                :textFieldProps="textFieldProps"
                                :datePickerProps="datePickerProps"
                                :label="`Date *`"
                                :rules="[rules.required]"
                                :return-value.sync="event.date"
                            >
                            </v-datetime-picker>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <v-text-field
                            prepend-icon="mdi-clipboard-text-clock"
                            type="number"
                            label="Reminder *"
                            v-model="event.reminder"
                            :rules="[rules.required, rules.int]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="6" md="6">
                        <v-combobox
                            v-model="event.emails"
                            :filter="filter"
                            :hide-no-data="!search"
                            :items="usersEmails"
                            hide-details
                            :search-input.sync="search"
                            hide-selected
                            label="Emails *"
                            multiple
                            small-chips
                            :rules="[rules.emails]"
                            required
                            flat
                            prepend-icon="mdi-email-plus"
                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-chip color="grey lighten-3" small>
                                        {{ search }}
                                    </v-chip>
                                </v-list-item>
                            </template>
                            <template
                                v-slot:selection="{
                                    attrs,
                                    item,
                                    parent,
                                    selected,
                                }"
                            >
                                <v-chip
                                    v-if="item === Object(item)"
                                    v-bind="attrs"
                                    color="grey lighten-3"
                                    :input-value="selected"
                                    small
                                >
                                    <span class="pr-2">
                                        {{ item.text }}
                                    </span>
                                    <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <v-select
                            :items="types"
                            label="Type *"
                            v-model="event.type"
                            prepend-icon="mdi-format-list-bulleted-type"
                            :rules="[rules.required]"
                            required
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Description *"
                            prepend-icon="mdi-card-text"
                            v-model="event.description"
                            hide-details
                            :rules="[rules.required]"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>

            <small>* indicates required field</small>
        </v-card-text>

        <v-card-actions class="mx-4 pb-5">
            <v-btn
                v-if="selectedEvent"
                color="red"
                text
                :loading="loading"
                @click="deleteDialog = true"
            >
                DELETE
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-if="!selectedEvent"
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="createEvent"
            >
                CREATE
            </v-btn>
        </v-card-actions>

        <!-- Delete Event -->
        <v-dialog max-width="300px" v-model="deleteDialog">
            <v-card :loading="loadingToDelete">
                <v-card-title class="text-h5">Delete Event</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this event?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteEvent"
                        text
                        color="error"
                        :loading="loadingToDelete"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'EventForm',
    props: {
        selectedEvent: {
            type: Object,
        },
        quoteId: String,
        postId: String,
    },
    data: () => {
        return {
            valid: false,
            loading: false,
            errorMsg: '',
            event: {
                title: '',
                date: null,
                emails: [],
                type: null,
            },
            menu: false,
            minDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            search: null,
            usersEmails: [],
            types: ['email', 'phone', 'meeting', 'other'],
            rules: {
                required: v => !!v || 'Required',
                emails: v =>
                    v.length > 0 ||
                    v.find(email => !/.+@.+\..+/.test(email.text)) ||
                    'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
                int: v => v > 0 || 'Time in minutes',
            },
            textFieldProps: {
                outlined: false,
                prependIcon: 'mdi-calendar',
            },
            datePickerProps: {
                min: new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 10),
            },
            loadingToDelete: false,
            deleteDialog: false,
            deleteError: false,
        }
    },
    computed: {
        ...mapState(['users']),
    },
    mounted() {
        if (this.selectedEvent) {
            const selectedEvent = _.cloneDeep(this.selectedEvent)
            const date = new Date(selectedEvent.date)
            const hours = date.getHours()
            const minutes = date.getMinutes()
            selectedEvent.date = `${selectedEvent.date.substring(0, 10)} ${
                hours < 10 ? '0' + hours : hours
            }:${minutes < 10 ? '0' + minutes : minutes}`
            selectedEvent.emails = selectedEvent.emails.map(email => {
                return {
                    text: email,
                }
            })
            this.event = selectedEvent
        }
        this.users.forEach(user => {
            this.usersEmails.push({ text: user.email })
        })
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async deleteEvent() {
            try {
                this.loadingToDelete = true
                this.deleteError = false
                this.errorMsg = ''
                const response = await API.deleteEvent(
                    this.selectedEvent.id,
                    this.quoteId,
                    this.postId
                )
                if (response.state === 'suceeded') {
                    this.$emit('removeEvent', this.postId)
                }
                this.$emit('closeEventForm')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingToDelete = false
            }
        },
        closeForm() {
            this.$emit('closeEventForm')
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        async createEvent() {
            try {
                this.loading = true
                this.errorMsg = ''
                const event = await API.createEvent({
                    ...this.event,
                    emails: this.event.emails.map(email => email.text),
                })
                await this.$emit('setEvent', event)
                this.$emit('closeEventForm')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
